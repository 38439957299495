<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("History") }}</h3>
      </div>
    </div>
    <div class="card-body detail">
      <b-container>
        <b-row>
          <b-col>
            <vue-timeline-update v-for="history in histories" :key="history.extra_history_data.history_id"
              :date="new Date(history.extra_history_data.history_date)" :title="history.name
                ? `${history.name} (${history.extra_history_data.user})`
                : `${secondment.display_name} (${history.extra_history_data.user})`
                " :description="getHistoryDescription(history)" :category="getHistoryCategory(history)"
              :icon="getHistoryIcon(history)" theme="light" :color="getHistoryColor(history)" />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DateService from "@/core/services/date.service";
import SecondmentService from "@/core/services/secondment/secondment.service";
export default {
  props: {
    secondment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      histories: [],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("History") },
    ]);
    await this.makeHistories()
  },
  methods: {
    async makeHistories() {
      await SecondmentService.getOne({ id: this.secondment.id, fields: 'history' }).then(response => {
        this.histories = response.data.history.map(history => {
          return {
            id: this.secondment.id,
            employees: [],
            clients: [],
            partners: [],
            contractors: [],
            country: history.country,
            date_start: history.date_start,
            date_end: history.date_end,
            name: history.name,
            description: history.description,
            type: history.type,
            status: history.status,
            past: history.past,
            created: history.created,
            updated: history.updated,
            company: 1,
            created_by: 3,
            extra_history_data: {
              type: history.history_type,
              user: history.history_user_fullname,
              history_date: history.history_date,
              history_id: history.history_id,
              change_reason: history.history_change_reason,
            },
          };
        });
      })
    },
    getHistoryColor(history) {
      switch (history.extra_history_data.type) {
        case "+":
          return "green";
        case "-":
          return "red";
        case "~":
          return "orange";
        default:
          return "grey";
      }
    },
    getHistoryCategory(history) {
      switch (history.extra_history_data.type) {
        case "+":
          return this.$t("Addition");
        case "-":
          return this.$t("Remove");
        case "~":
          return this.$t("Edit");
        default:
          return this.$t("Edit");
      }
    },
    getHistoryIcon(history) {
      switch (history.extra_history_data.type) {
        case "+":
          return "add";
        case "-":
          return "minus";
        case "~":
          return "edit";
        default:
          return "";
      }
    },
    getHistoryDescription(history) {
      const historyChangeReason = history.extra_history_data.change_reason;
      const historyUser = history.extra_history_data.user;
      const historyDate = DateService.format(history.extra_history_data.history_date);

      const secondmentChangeString = `${this.$t("Assignment changed by ")} ${historyUser} ${this.$t("on")} ${historyDate}`;
      const secondmentReasonString = historyChangeReason
        ? `${this.$t("Change reason")} ${historyChangeReason}`
        : "";

      return `${secondmentChangeString}<br>${secondmentReasonString}`;
    },
  }
};
</script>

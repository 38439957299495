var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("History")))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('b-container', [_c('b-row', [_c('b-col', _vm._l(_vm.histories, function (history) {
    return _c('vue-timeline-update', {
      key: history.extra_history_data.history_id,
      attrs: {
        "date": new Date(history.extra_history_data.history_date),
        "title": history.name ? "".concat(history.name, " (").concat(history.extra_history_data.user, ")") : "".concat(_vm.secondment.display_name, " (").concat(history.extra_history_data.user, ")"),
        "description": _vm.getHistoryDescription(history),
        "category": _vm.getHistoryCategory(history),
        "icon": _vm.getHistoryIcon(history),
        "theme": "light",
        "color": _vm.getHistoryColor(history)
      }
    });
  }), 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }